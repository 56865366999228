<template>
  <b-card
    :title="[!memberId ? 'เพิ่ม' : 'แก้ไข'] + 'ข้อมูลผู้ใช้งานระบบ'"
    class="form-member"
  >
    <b-modal
      ref="refModalDatePickerBirth"
      centered
      title="วันเกิด"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="dateBirth"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerBirth.hide()"
      />
    </b-modal>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <b-row :class="`justify-content-md-center `">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    เพศ
                  </template>
                  <div
                    class="demo-inline-spacing"
                    style="margin-top: -20px;"
                  >
                    <b-form-radio
                      v-model="gender"
                      name="gender-radios"
                      value="M"
                    >
                      ชาย
                    </b-form-radio>
                    <b-form-radio
                      v-model="gender"
                      name="gender-radios"
                      value="F"
                    >
                      หญิง
                    </b-form-radio>
                    <b-form-radio
                      v-model="gender"
                      name="gender-radios"
                      value="U"
                    >
                      ไม่ระบุ
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ชื่อ <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      trim
                      placeholder="ชื่อ"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกชื่อ' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    นามสกุล <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="surname"
                    rules="required"
                  >
                    <b-form-input
                      id="surname"
                      v-model="surname"
                      trim
                      placeholder="นามสกุล"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกนามสกุล' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ชื่อ (ภาษาอังกฤษ)
                  </template>
                  <b-form-input
                    id="nameEng"
                    v-model="nameEng"
                    trim
                    placeholder="ชื่อ (ภาษาอังกฤษ)"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    นามสกุล (ภาษาอังกฤษ)
                  </template>
                  <b-form-input
                    id="surnameEng"
                    v-model="surnameEng"
                    trim
                    placeholder="นามสกุล (ภาษาอังกฤษ)"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    วันเกิด
                  </template>
                  <b-input-group>
                    <b-form-input
                      id="dateBirth"
                      :value="resolveFormatDate(dateBirth)"
                      trim
                      readonly
                      placeholder="วันเกิด"
                      @click="refModalDatePickerBirth.show()"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        :disabled="dateBirth ? false : true"
                        @click="dateBirth = null"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    เบอร์โทร
                  </template>
                  <b-form-input
                    id="tel"
                    v-model="tel"
                    trim
                    placeholder="เบอร์โทร"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    อีเมล
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    :rules="email ? 'required|email' : ''"
                  >
                    <b-form-input
                      id="email"
                      v-model="email"
                      trim
                      placeholder="อีเมล"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอกอีเมลให้ถูกต้อง' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ที่อยู่
                  </template>
                  <b-form-textarea
                    v-model="address"
                    placeholder="ที่อยู่"
                    rows="3"
                    max-rows="8"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    แผนก
                  </template>
                  <b-overlay
                    :show="overlayLoadDepartment"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                  >
                    <v-select
                      v-model="department"
                      :options="dataDepartment"
                      label="department_desc"
                      :reduce="department_desc => department_desc.department_id"
                      :clearable="true"
                      :searchable="true"
                    />
                  </b-overlay>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ตำแหน่ง
                  </template>
                  <b-overlay
                    :show="overlayLoadPosition"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                  >
                    <v-select
                      v-model="position"
                      :options="dataPosition"
                      label="position_desc"
                      :reduce="position_desc => position_desc.position_id"
                      :clearable="true"
                      :searchable="true"
                    />
                  </b-overlay>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ประเภทผู้ใช้
                  </template>
                  <b-overlay
                    :show="overlayLoadMemberType"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                  >
                    <v-select
                      v-model="memberType"
                      :options="dataMemberType"
                      label="membertype_desc"
                      :reduce="membertype_desc => membertype_desc.member_type_id"
                      :clearable="false"
                      :searchable="true"
                    />
                  </b-overlay>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    Username
                    <span
                      v-if="!memberId"
                      class="text-danger required-font-style"
                    >*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      trim
                      placeholder="Username"
                      autocomplete="off"
                      :disabled="memberId ? true : false"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอก Username' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    Password <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="password"
                    :rules="!memberId ? 'required' : password || passwordConfirm ? 'required' : ''"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      type="password"
                      trim
                      placeholder="Password"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอก Password' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    Confirm Password <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="passwordConfirm"
                    :rules="!memberId ? 'required|confirmed:password' : password || passwordConfirm ? 'required|confirmed:password' : ''"
                  >
                    <b-form-input
                      id="passwordConfirm"
                      v-model="passwordConfirm"
                      type="password"
                      trim
                      placeholder="Confirm Password"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ !validationContext.errors[0] ? '' : validationContext.errors[0] == 'The passwordConfirm field is required' ? 'กรุณา Confirm Password' : 'รหัสผ่านยืนยันไม่ตรงกัน' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-overlay
                      :show="overlaySubmit"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        variant="primary"
                        class="mr-2"
                        type="submit"
                      >
                        <feather-icon icon="SaveIcon" />
                        บันทึกข้อมูล
                      </b-button>
                    </b-overlay>
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      :to="{ path: '/member' }"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BFormRadio, BFormTextarea, BOverlay, BModal, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VDatePicker } from 'vuetify/lib'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import useMemberForm from './useMemberForm'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BOverlay,
    BModal,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    VDatePicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const {
      memberId,

      // variable input
      name,
      surname,
      nameEng,
      surnameEng,
      tel,
      email,
      username,
      password,
      passwordConfirm,

      // variable select list
      department,
      position,
      memberType,

      // variable textarea
      address,

      // variable radio
      gender,

      // variable datePicker
      dateBirth,

      // variable data
      dataDepartment,
      dataPosition,
      dataMemberType,

      // variable ref
      refModalDatePickerBirth,

      // loading animation
      overlayLoadDepartment,
      overlayLoadPosition,
      overlayLoadMemberType,
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,
    } = useMemberForm()

    // vee validate
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      memberId,

      // variable input
      name,
      surname,
      nameEng,
      surnameEng,
      tel,
      email,
      username,
      password,
      passwordConfirm,

      // variable select list
      department,
      position,
      memberType,

      // variable textarea
      address,

      // variable radio
      gender,

      // variable datePicker
      dateBirth,

      // variable data
      dataDepartment,
      dataPosition,
      dataMemberType,

      // variable ref
      refModalDatePickerBirth,

      // loading animation
      overlayLoadDepartment,
      overlayLoadPosition,
      overlayLoadMemberType,
      overlaySubmit,

      // function
      fncSubmit,
      resolveFormatDate,

      // helper
      isLocalServer,

      // vee validate
      refFormObserver,
      getValidationState,
      resetForm,
      required,
    }
  },
}
</script>

<style>
.required-font-style
{
  font-weight: bold;
  font-size: 18px;;
}

.form-member .form-row > legend
{
  margin-top: 10px;
}

.form-member .delete-margin {
  margin-top: -40px;
}

.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
