import { ref, computed, onMounted } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMemberForm() {
  const memberId = router.currentRoute.params.id

  // Notification
  const toast = useToast()

  // variable input
  const name = ref(null)
  const surname = ref(null)
  const nameEng = ref(null)
  const surnameEng = ref(null)
  const tel = ref(null)
  const email = ref(null)
  const username = ref(null)
  const password = ref(null)
  const passwordConfirm = ref(null)

  // variable select list
  const department = ref(null)
  const position = ref(null)
  const memberType = ref(null)

  // variable textarea
  const address = ref(null)

  // variable radio
  const gender = ref('M')

  // variable datePicker
  const dateBirth = ref(null)

  // variable data
  const dataDepartment = ref([])
  const dataPosition = ref([])
  const dataMemberType = ref([])

  // variable ref
  const refModalDatePickerBirth = ref(null)

  // loading animation
  const overlayLoadDepartment = ref(false)
  const overlayLoadPosition = ref(false)
  const overlayLoadMemberType = ref(false)
  const overlaySubmit = ref(false)

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      member_id: memberId,
      sex: gender.value,
      name: name.value,
      surname: surname.value,
      name_en: nameEng.value,
      surname_en: surnameEng.value,
      birth_date: dateBirth.value,
      tel: tel.value,
      email: email.value,
      address: address.value,
      department_code: department.value,
      position_code: position.value,
      member_type: memberType.value,
      member_no: username.value,
      password: password.value,
    }

    if (!memberId) delete payload.member_id
    else delete payload.username

    if (!memberId) {
      axios
        .post('/Member', { requestData: payload })
        .then(() => {
          router.push('/member')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'บันทึกข้อมูลผู้ใช้งานระบบสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการบันทึกข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    } else {
      axios
        .put('/Member', { requestData: payload })
        .then(() => {
          router.push('/member')

          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'แก้ไขข้อมูลผู้ใช้งานระบบสำเร็จ',
            },
          })

          overlaySubmit.value = false
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              text: `พบข้อผิดพลาดในการแก้ไขข้อมูล [${error}]`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          overlaySubmit.value = false
        })
    }
  }

  // eslint-disable-next-line arrow-body-style
  const fncFetchDateEdit = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/Member/${memberId}`)
        .then(response => {
          // console.log('fncFetchDateEdit', response.data)

          if (response.data.responseData.length) {
            const responseData = response.data.responseData[0]

            name.value = responseData.name
            surname.value = responseData.surname
            nameEng.value = responseData.name_en
            surnameEng.value = responseData.surname_en
            tel.value = responseData.tel
            email.value = responseData.email
            username.value = responseData.member_no
            address.value = responseData.address
            gender.value = responseData.sex
            dateBirth.value = responseData.birth_date

            resolve(responseData)
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                text: 'ไม่พบข้อมูล',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

            router.push('/member')

            resolve()
          }
        })
        .catch(error => reject(error))
    })
  }

  const fncFetchDataDepartment = departmentId => {
    overlayLoadDepartment.value = true

    axios
      .get('/Department')
      .then(response => {
        dataDepartment.value = response.data.value.data
        if (dataDepartment.value.length && departmentId) department.value = Number(departmentId)

        overlayLoadDepartment.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดข้อมูลแผนก [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadDepartment.value = false
      })
  }

  const fncFetchDataPosition = positionId => {
    overlayLoadPosition.value = true

    axios
      .get('/Position')
      .then(response => {
        dataPosition.value = response.data.value.data
        if (dataPosition.value.length && positionId) position.value = Number(positionId)

        overlayLoadPosition.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดข้อมูลตำแหน่ง [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadPosition.value = false
      })
  }

  const fncFetchDataMemberType = memberTypeId => {
    overlayLoadMemberType.value = true

    axios
      .get('/MemberType')
      .then(response => {
        dataMemberType.value = response.data.value.data
        if (dataMemberType.value.length) {
          if (memberTypeId) memberType.value = Number(memberTypeId)
          else memberType.value = dataMemberType.value[0].member_type_id
        }

        overlayLoadMemberType.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดข้อมูลประเภทผู้ใช้ [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlayLoadMemberType.value = false
      })
  }

  /* กำหนดรูปแบบ ว/ด/ป */
  /* กำหนดปีเป็น พ.ศ. */
  const toBuddhistYear = (val, format) => {
    const christianYear = val.format('YYYY')
    // eslint-disable-next-line radix
    const buddhishYear = (parseInt(christianYear) + 543).toString()
    return val
      .format(
        format
          .replace('YYYY', buddhishYear)
          .replace('YY', buddhishYear.substring(2, 4)),
      )
      .replace(christianYear, buddhishYear)
  }

  const resolveFormatDate = val => (val
    ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
    : null)

  // helper
  const isLocalServer = computed(() => {
    const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
    return getServerName === 'localhost'
  })

  onMounted(async () => {
    if (memberId) {
      const getDataEdit = await fncFetchDateEdit()
      fncFetchDataDepartment(getDataEdit.department_code)
      fncFetchDataPosition(getDataEdit.position_code)
      fncFetchDataMemberType(getDataEdit.member_type)
    } else {
      fncFetchDataDepartment()
      fncFetchDataPosition()
      fncFetchDataMemberType()
    }
  })

  return {
    memberId,

    // variable input
    name,
    surname,
    nameEng,
    surnameEng,
    tel,
    email,
    username,
    password,
    passwordConfirm,

    // variable select list
    department,
    position,
    memberType,

    // variable textarea
    address,

    // variable radio
    gender,

    // variable datePicker
    dateBirth,

    // variable data
    dataDepartment,
    dataPosition,
    dataMemberType,

    // variable ref
    refModalDatePickerBirth,

    // loading animation
    overlayLoadDepartment,
    overlayLoadPosition,
    overlayLoadMemberType,
    overlaySubmit,

    // function
    fncSubmit,
    resolveFormatDate,

    // helper
    isLocalServer,
  }
}
